import { Badge, IconButton, withStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { createStyles } from "@material-ui/styles";
import clsx from "clsx";
import * as eow from "date-fns/endOfWeek";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import isValid from "date-fns/isValid";
import isWithinInterval from "date-fns/isWithinInterval";
import isLocale from "date-fns/locale/is";
import * as sow from "date-fns/startOfWeek";
import React, { useState } from "react";

export const startOfWeek = (date: Date) =>
  sow.default(date, { locale: isLocale });
export const endOfWeek = (date: Date) =>
  eow.default(date, { locale: isLocale });

type WeekPickerProps = {
  classes: any;
  onChange: (start?: Date, end?: Date) => void;
  numberForDay: (date: Date) => number | null;
  clearable: boolean;
};

const DayBadge = withStyles(() => ({
  badge: {
    right: 8,
    top: 8,
  },
}))(Badge);

function WeekPicker({
  classes,
  onChange,
  numberForDay,
  clearable,
}: WeekPickerProps) {
  const [date, setDate] = useState<Date | undefined>(new Date());

  const formatWeekSelectLabel = (
    _date: MaterialUiPickersDate,
    invalidLabel: string
  ) => {
    if (!date) return "Allar vikur";

    const startDate = startOfWeek(_date as Date);
    const endDate = endOfWeek(_date as Date);

    const startDay = format(startDate, "do", { locale: isLocale });
    const endDay = format(endDate, "do", { locale: isLocale });

    const startMonth = format(startDate, "MMM", { locale: isLocale });
    const endMonth = format(endDate, "MMM", { locale: isLocale });

    return _date && isValid(_date)
      ? `${startDay}${
          startMonth !== endMonth ? ` ${startMonth}` : ""
        } - ${endDay} ${endMonth}`
      : invalidLabel;
  };

  const renderWrappedWeekDay = (
    date: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean
  ) => {
    const start = startOfWeek(selectedDate as Date);
    const end = endOfWeek(selectedDate as Date);

    const dayIsBetween = isWithinInterval(date as Date, { start, end });

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isSameDay(date as Date, start),
      [classes.endHighlight]: isSameDay(date as Date, end),
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    const day = (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(date as Date, "d")} </span>
        </IconButton>
      </div>
    );

    let count = numberForDay(date as Date);
    return count ? (
      <DayBadge
        variant="dot"
        color="secondary"
        badgeContent={numberForDay(date as Date)}
      >
        {day}
      </DayBadge>
    ) : (
      day
    );
  };

  return (
    <DatePicker
      label="Vika"
      value={date}
      clearable={clearable}
      clearLabel="Sýna alla tíma"
      onChange={(d) => {
        if (!d) {
          onChange(undefined, undefined);
          setDate(undefined);
        } else {
          onChange(startOfWeek(d), endOfWeek(d));
          setDate(startOfWeek(d as Date));
        }
      }}
      renderDay={renderWrappedWeekDay}
      labelFunc={formatWeekSelectLabel}
    />
  );
}

const styles = createStyles((theme: any) => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

export default withStyles(styles)(WeekPicker);
