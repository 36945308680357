import DoneAllIcon from "@material-ui/icons/DoneAll";
import "firebase/firestore";
import React, {Fragment} from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {AppUser} from "common/types";
import {Doc, TimeQuery} from "./TimeQuery";
import {Subtitle} from "./UI";
import {MaterialCard, validateAndMove} from "./LogMaterials";
import {useMaterialLogs} from "./useMaterialLogs";

const confirmMaterialLogs = ({times: mlogs, user}: { times: Doc[], user: AppUser }) => (
  <Fragment>
    {(mlogs?.length || 0) < 1 ? (
      <Subtitle>Engar efnisskráningar til að staðfesta</Subtitle>
    ) : null}
    <TransitionGroup>
      {mlogs?.map((doc) => {
        return (
          <CSSTransition key={doc.id} timeout={500} classNames="item">
            <div>
              <MaterialCard
                key={doc.id}
                doc={doc}
                showName
                submit={async (mlog) => await validateAndMove(user, 'logged', doc)(mlog)}
                submitText="Skrá"
                submitIcon={<DoneAllIcon/>}
                errors={{}}/>
            </div>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  </Fragment>
);

export default function ConfirmMaterials({user}: { user: AppUser }) {
  return (
    <TimeQuery
      showDayCount={true}
      allowShowAll={true}
      user={user}
      query={useMaterialLogs(user, 'pending')}
      RenderTimes={confirmMaterialLogs}
    />
  );
}
