import { Button, Grid } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { DatePicker } from "@material-ui/pickers";
import { format, startOfMonth } from "date-fns";
import firebase from "firebase";
import React, { useContext, useRef, useState } from "react";
import { AppContext } from "./App";
import { logError } from "./errors";

export function AdminReport() {
  const db = firebase.app().firestore();
  const { withLoading } = useContext(AppContext);
  const [from, setFrom] = useState(startOfMonth(new Date()))
  const [to, setTo] = useState(new Date())

  const downloadHref = useRef<HTMLAnchorElement>(null)

  async function download() {
    let csv = "\ufeffdate;name;employee;hours;overtime;type;device;group;task;sent-time;comment"

    function appendDocs(docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]) {
      for (const doc of docs) {
        let data = doc.data()
        try {
          let date = new Date(data.date * 1000)
          let dateStr = `${date.getFullYear()}-${date.getUTCMonth() + 1}-${date.getDate()}`
          let sent = data.sentDate ? new Date(data.sentDate).toISOString() : ''
          let overtime = (data.overtime || 0).toString().replace('.', ',')
          let hours = (data.hours || 0).toString().replace('.', ',')
          let comment = data.comment ? data.comment.replace(';', '') : ''
          let task = data.task || ''
          let device = data.device || ''
          let employee = data.employee || ''
          csv += `\n${dateStr};${data.name};${employee};${hours};${overtime};${data.kind};${device};${data.group};${task};${sent};${comment}`
        } catch (e) {
          alert('Óvænt villa kom upp, vinsamlegast hafið samband við kerfisstjóra')
          logError(e)
          break;
        }
      }
    }

    const sent = await db.collection('records/times/sent').where('date', '>=', (+from / 1000)).where('date', '<=', (+to / 1000)).get()
    const pending = await db.collection('records/times/pending').where('date', '>=', (+from / 1000)).where('date', '<=', (+to / 1000)).get()

    appendDocs(sent.docs)
    appendDocs(pending.docs)

    if (downloadHref.current) {
      downloadHref.current.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`
      downloadHref.current.click()
    }
  }

  return <Grid container>
    <Grid item xs={3}>

      <DatePicker
        label="Frá"
        value={from}
        format="d. MMM yyyy"
        onChange={d => setFrom(d ?? from)}
        animateYearScrolling
      />
    </Grid>
    <Grid item xs={3}>
      <DatePicker
        label="Til"
        value={to}
        format="d. MMM yyyy"
        onChange={d => setTo(d ?? to)}
        animateYearScrolling
      />
    </Grid>
    <Grid item xs={12}>
      <Button
        style={{ marginTop: 20 }}
        endIcon={<CloudDownloadIcon />}
        color="primary"
        variant="contained"
        onClick={() => withLoading(download)}>
        Sækja
      </Button>
    </Grid>
    <a ref={downloadHref} download={`timak-${format(from, 'dd-MM-yyyy')}_${format(to, 'dd-MM-yyyy')}.csv`}></a>
  </Grid>
}