import { Button, Grid } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { DatePicker } from "@material-ui/pickers";
import { format, startOfMonth } from "date-fns";
import firebase from "firebase";
import React, { useContext, useRef, useState } from "react";
import { AppContext } from "./App";
import { logError } from "./errors";

export function AdminMaterialReport() {
  const db = firebase.app().firestore();
  const { withLoading } = useContext(AppContext);
  const [from, setFrom] = useState(startOfMonth(new Date()))
  const [to, setTo] = useState(new Date())

  const downloadHref = useRef<HTMLAnchorElement>(null)

  async function download() {
    let csv = "\ufeffdate;material;task;amount;comment;employee"

    function appendDocs(docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]) {
      for (const doc of docs) {
        let data = doc.data()
        try {
          let date = new Date(data.date * 1000)
          let dateStr = `${date.getFullYear()}-${date.getUTCMonth() + 1}-${date.getDate()}`
          let material = data.material || ''
          let task = data.task || ''
          let amount = data.amount || ''
          let comment = data.comment ? data.comment.replace(';', '') : ''
          let employee = data.employee || ''

          csv += `\n${dateStr};${material};${task};${amount};${comment};${employee}`
        } catch (e) {
          alert('Óvænt villa kom upp, vinsamlegast hafið samband við kerfisstjóra')
          logError(e)
          break;
        }
      }
    }

    const logged = await db.collection('records/materials/logged').where('date', '>=', (+from / 1000)).where('date', '<=', (+to / 1000)).get()

    appendDocs(logged.docs)

    if (downloadHref.current) {
      downloadHref.current.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`
      downloadHref.current.click()
    }
  }

  return <Grid container>
    <Grid item xs={3}>

      <DatePicker
        label="Frá"
        value={from}
        format="d. MMM yyyy"
        onChange={d => setFrom(d ?? from)}
        animateYearScrolling
      />
    </Grid>
    <Grid item xs={3}>
      <DatePicker
        label="Til"
        value={to}
        format="d. MMM yyyy"
        onChange={d => setTo(d ?? to)}
        animateYearScrolling
      />
    </Grid>
    <Grid item xs={12}>
      <Button
        style={{ marginTop: 20 }}
        endIcon={<CloudDownloadIcon />}
        color="primary"
        variant="contained"
        onClick={() => withLoading(download)}>
        Sækja
      </Button>
    </Grid>
    <a ref={downloadHref} download={`timak-efnisskraning-${format(from, 'dd-MM-yyyy')}_${format(to, 'dd-MM-yyyy')}.csv`}></a>
  </Grid>
}