import DateFnsUtils from "@date-io/date-fns";
import {
  AppBar,
  Box,
  Container,
  createTheme,
  Fade,
  IconButton,
  LinearProgress,
  makeStyles,
  Menu,
  MenuItem, ThemeProvider,
  Toolbar,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { blue, pink } from "@material-ui/core/colors";
import MenuIcon from "@material-ui/icons/Menu";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import format from "date-fns/format";
import isLocale from "date-fns/locale/is";
import firebase from "firebase/app";
import "firebase/auth";
import React, { createContext, useState } from "react";
import { AdminReport } from "./AdminReport";
import AdminSend from "./AdminSend";
import AdminUsers from "./AdminUsers";
import ConfirmTimes from "./ConfirmTimes";
import ConfirmMaterials from "./ConfirmMaterials";
import ApproveTimes from "./ApproveTimes";
import { GENERIC_ERROR } from "./constants";
import { logError } from "./errors";
import Login from "./Login";
import LogTimes from "./LogTimes";
import SentTimes from "./SentTimes";
import { Label, Subtitle } from "./UI";
import useUser from "./useUser";
import LogMaterials from "./LogMaterials";
import {AdminMaterialReport} from "./AdminMaterialReport";

type Page = "log" | "logmaterial" | "confirmmaterial" | "overview" | "approve" | "confirm" | "admin-send" | "admin-users" | "admin-report" | "admin-material-report";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  name: {
    marginLeft: "auto",
  },
  fullHeight: {
    ...theme.mixins.toolbar,
  },
  tabs: {
    marginLeft: 30,
  },
}));

class IsLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date) {
    return format(date, "d MMM yyyy", { locale: this.locale });
  }
}

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    logError(error);
  }

  render() {
    return this.state.hasError ? (
      <Subtitle>{GENERIC_ERROR}</Subtitle>
    ) : (
      this.props.children
    );
  }
}

export interface AppContext {
  loading: number;
  setLoading: (loading: boolean) => void;
  withLoading: <A>(f: () => Promise<A>) => Promise<A>;
}

export const AppContext = createContext<AppContext>({
  loading: 0,
  setLoading: () => { },
  withLoading: (f) => f(),
});

function useAppContext(): AppContext {
  const [loading, setLoading] = useState(0);

  return {
    loading: loading,
    setLoading: (isLoading: boolean) => {
      setLoading(Math.max(0, loading + (isLoading ? 1 : -1)));
    },
    withLoading: async (f) => {
      setLoading(loading + 1);
      try {
        return await f();
      } finally {
        setLoading(Math.max(0, loading - 1));
      }
    },
  };
}

const App = () => {
  let prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  let classes = useStyles();
  let appContext = useAppContext();
  let user = useUser();

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: blue,
          secondary: pink,
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  let [menuAnchor, setMenuAnchor] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  let [selectedPage, setPage] = useState<Page | null>(null);

  const menuOpen = Boolean(menuAnchor);

  function handleMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setMenuAnchor(e.currentTarget);
  }

  function handleClose() {
    setMenuAnchor(null);
  }

  function page(): [string, React.ReactElement] {
    switch (user) {
      case "error":
        return ["Villa", <h3>{GENERIC_ERROR}</h3>];
      case "none":
        return ["Innskráning", <Login />];
      case "loading":
        return ["Ræsi stórtölvu…", <div />];
      default: {
        if (selectedPage === "log" || (!selectedPage && user.role === "user")) return ["Skrá tíma", <LogTimes user={user} />];
        if (selectedPage === "logmaterial") return ["Skrá efni", <LogMaterials user={user} />];
        if (selectedPage === "confirmmaterial") return ["Staðfesta efni", <ConfirmMaterials user={user} />];
        if (selectedPage === "overview") return ["Sendir tímar", <SentTimes user={user} />];
        if (selectedPage === "approve" || (!selectedPage && user.role === "manager")) return ["Samþykkja tíma", <ApproveTimes user={user} />];
        if (selectedPage === "confirm") return ["Staðfesta tíma", <ConfirmTimes user={user} />];
        if (selectedPage === "admin-send") return ["Senda tíma (admin)", <AdminSend user={user} />];
        if (selectedPage === "admin-users" || (!selectedPage && user.role === 'admin')) return ["Notendur (admin)", <AdminUsers />];
        if (selectedPage === "admin-report") return ["Tímaskýrslur (admin)", <AdminReport />]
        if (selectedPage === "admin-material-report") return ["Efnisskýrslur (admin)", <AdminMaterialReport />]
        return ["Villa", <h3>{GENERIC_ERROR}</h3>];
      }
    }
  }

  const menuSelect = (page: Page) => () => {
    setPage(page);
    handleClose();
  }

  const [title, view] = page();

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <AppBar color={prefersDarkMode ? "default" : "primary"}>
            <Toolbar variant="dense">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="fade-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={menuOpen}
                onClose={handleClose}
                TransitionComponent={Fade}>
                <MenuItem onClick={menuSelect('log')}>Skrá tíma</MenuItem>
                {typeof user === "object" && ([0, 1,2,3,4,5].includes(user.group)) ? (
                  <MenuItem onClick={menuSelect('logmaterial')}>Skrá efni</MenuItem>
                ) : null}
                {typeof user === "object" && (user.number === 23651) ? (
                  <MenuItem onClick={menuSelect('confirmmaterial')}>Staðfesta efni</MenuItem>
                ) : null}
                <MenuItem onClick={menuSelect('overview')}>Sendir tímar</MenuItem>
                {typeof user === "object" &&
                  (user.role === "manager" || user.role === "admin") ? (
                  <MenuItem onClick={menuSelect('approve')}>Samþykkja tíma</MenuItem>
                  ) : null}
                {typeof user === "object" && user.role === "admin" ? (
                  <MenuItem onClick={menuSelect('confirm')}>Staðfesta tíma</MenuItem>
                ) : null}
                {typeof user === "object" && user.role === "admin" ? (
                  <MenuItem onClick={menuSelect('admin-send')}>Senda tíma (admin)</MenuItem>
                ) : null}
                {typeof user === "object" && user.role === "admin" ? (
                  <MenuItem onClick={menuSelect('admin-users')}>Notendur (admin)</MenuItem>
                ) : null}
                {typeof user === "object" && user.role === "admin" ? (
                  <MenuItem onClick={menuSelect('admin-report')}>Tímaskýrslur (admin)</MenuItem>
                ) : null}
                {typeof user === "object" && user.role === "admin" ? (
                    <MenuItem onClick={menuSelect('admin-material-report')}>Efnisskýrslur (admin)</MenuItem>
                ) : null}
                <MenuItem
                  onClick={() => {
                    firebase.auth().signOut();
                    handleClose();
                  }}>
                  Útskráning
                </MenuItem>
              </Menu>
              <Typography variant="h6">{title}</Typography>
              <Label className={classes.name}>
                {typeof user === "object" && user.name.split(" ")[0]}
              </Label>
            </Toolbar>
            {appContext.loading > 0 || user === "loading" ? (
              <LinearProgress style={{ zIndex: 1000 }} color="secondary" />
            ) : (
              <div style={{ height: 4 }} />
            )}
          </AppBar>
        </div>
        <Container maxWidth="md">
          <AppContext.Provider value={appContext}>
            <MuiPickersUtilsProvider utils={IsLocalizedUtils} locale={isLocale}>
              <Box my={10}>{view}</Box>
            </MuiPickersUtilsProvider>
          </AppContext.Provider>
        </Container>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
