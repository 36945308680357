import {
  Card,
  CardContent,
  Grid,
  makeStyles
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import DeleteIcon from "@material-ui/icons/Delete";
import {Alert, Autocomplete, createFilterOptions} from "@material-ui/lab";
import {DatePicker} from "@material-ui/pickers";

import {useDebounce} from "use-debounce";
import {AppContext} from "./App";
import {GENERIC_ERROR, REQUIRED} from "./constants";
import {logError} from "./errors";
import useTasks from "./useTasks";
import firebase from "firebase/app";

import {AppUser} from "common/types";
import {useCollection} from "./useCollection";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Subtitle} from "./UI";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Button} from "@material-ui/core";

type MaterialLog = {
  date: number,
  user: string | null,
  name: string,
  task: string
  material: string,
  amount: number,
  group: number,
  comment: string,
}

async function newMaterialLog(
  db: firebase.firestore.Firestore,
  collection: string,
  user: string | null,
  group: number,
) {
  let m = db.collection('records').doc('materials').collection(collection).doc();
  await m.set({
    date: Math.floor(Date.now() / 1000),
    user,
    group,
  });
}

export function validateAndMove(user: AppUser, collection: 'pending' | 'logged', doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
  return async function (mlog: MaterialLog): Promise<{ [key: string]: string }> {
    if (!mlog.date) return {date: REQUIRED};
    if (!mlog.task) return {task: REQUIRED};
    if (!mlog.amount) return {amount: REQUIRED};
    if (!mlog.material) return {material: REQUIRED};
    if (!mlog.comment) return {comment: REQUIRED};
    if (!mlog.user) return {user: REQUIRED};

    let nextDoc = firebase
      .app()
      .firestore()
      .collection("records")
      .doc("materials")
      .collection(collection)
      .doc(doc.id);

    try {
      const name = mlog.name == null || mlog.name == "" ? user.name : mlog.name;
      const group = mlog.group == null ? user.group : mlog.group;
      await nextDoc.set({...mlog, state: collection, name, group});
    } catch (e) {
      logError(e);
      return {error: GENERIC_ERROR};
    }
    return {};
  };
}

function LogMaterials({user}: { user: AppUser }) {
  const app = firebase.app();
  const db = app.firestore();
  const pn = app.auth().currentUser?.phoneNumber || "";

  const [materials, loading, error] = useCollection(
    db
      .collection("records")
      .doc("materials")
      .collection("drafts")
      .where("user", "==", pn)
  );

  return loading ? null : error ? (
    <Subtitle>
      Aðgerð tókst ekki, athugið nettenginu eða reynið aftur síðar.
    </Subtitle>
  ) : (
    <Fragment>
      <TransitionGroup>
        {materials?.docs.map((d) => {
          return (
            <CSSTransition key={d.id} timeout={500} classNames="item">
              <div>
                <MaterialCard
                  doc={d}
                  showName={false}
                  submit={validateAndMove(user, 'pending', d)}
                  submitText="Senda"
                  submitIcon={<SendIcon/>}
                  errors={{}}/>
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      <div>
        <Button
          variant="contained"
          size="small"
          onClick={() => newMaterialLog(db, 'drafts', user.phone, user.group)}>
          Nýr efnisskráning
        </Button>
      </div>
    </Fragment>
  );
}

export default LogMaterials;

type Doc = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

interface MaterialCardProps {
  showName?: boolean;
  doc: Doc;
  submit: (t: any) => Promise<{ [key: string]: string }>;
  submitText: string;
  submitIcon: JSX.Element;
  errors: { [key: string]: string };
}

type Errors = { [key: string]: string };

type MaterialFields =
  | "amount"
  | "comment"

const useStyles = makeStyles(() => ({
  name: {
    margin: 0,
    fontWeight: "bold",
  },
}));

export function MaterialCard({showName, doc, submit, submitText, submitIcon}: MaterialCardProps) {
  const db = firebase.app().firestore();
  let [mlog, setTime] = useState({...doc.data()});
  let [errors, setErrors] = useState<Errors>({});
  let [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  let [debouncedTime] = useDebounce(mlog, 400);
  let {withLoading} = useContext(AppContext);
  let {loading: tasksLoading, workTasks} = useTasks();
  let [materialsSnapshot, materialsLoading, materialsError] = useCollection(db.collection("materials").orderBy("number"));
  let classes = useStyles();

  type Material = {
    number: string,
    description: string
  }
  let materials: Material[] = (materialsLoading || materialsError) ? [] : materialsSnapshot!.docs.map((m) => {
    let data = m.data();
    return data as Material;
  })

  useEffect(() => {
    withLoading(() => doc.ref.update(debouncedTime));
  }, [debouncedTime]);

  const del = async () => {
    setButtonsDisabled(true);
    await doc.ref.delete();
    setButtonsDisabled(false);
  };

  const _submit = async () => {
    setErrors({});
    setButtonsDisabled(true);
    try {
      let _mlog = {...mlog};
      if (mlog.amount) _mlog.amount = Number(mlog.amount);
      let errors = await withLoading(async () => await submit(_mlog));
      if (Object.keys(errors).length === 0) {
        doc.ref.delete();
      } else {
        setErrors(errors);
        setButtonsDisabled(false);
      }
    } catch (e) {
      logError(e);
      setErrors({error: GENERIC_ERROR});
    }
  };

  function fieldProps(key: MaterialFields, helperText?: string) {
    return {
      InputLabelProps: {shrink: true},
      value: mlog[key] === undefined ? "" : mlog[key],
      error: !!errors[key],
      helperText: helperText || errors[key],
      onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => setTime({...mlog, [key]: e.target.value}),
    };
  }

  let taskText =
    workTasks.find((task) => task.number === mlog.task)?.description ||
    (!mlog.task ? "" : "Sækji verknúmer…");

  let materialText =
    materials.find((m) => m.number === mlog.material)?.description ||
    (!mlog.material ? "" : "Sækji efni…");

  let filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (task: { number: string, description: string }) => `${task.number} ${task.description}`,
  });

  return (
    <Card style={{marginBottom: 20}} elevation={4}>
      <CardContent>
        {" "}
        <Grid container spacing={2}>
          {showName ? (
            <Grid item xs={12}>
              <p className={classes.name}>{mlog.name}</p>
            </Grid>
          ) : null}
          <Grid item xs={6} sm={2} md={1}>
            <DatePicker
              required
              error={!!errors.date}
              helperText={errors.date}
              label="Dagsetning"
              value={mlog["date"] && mlog["date"] * 1000}
              onChange={(d) =>
                d && setTime({...mlog, date: Math.floor(d.getTime() / 1000)})
              }
              format="d MMM"
            />
          </Grid>


          <Grid item xs={12} sm={3}>
            <Autocomplete
              filterOptions={filterOptions}
              options={workTasks}
              loading={tasksLoading}
              onChange={(_, newValue) =>
                setTime({...mlog, task: newValue && newValue.number})
              }
              value={mlog.task}
              loadingText="Sæki verknúmer…"
              getOptionLabel={(task) =>
                typeof task === "string" ? task : task.number
              }
              getOptionSelected={(option, value) =>
                option.number === ((value as unknown) as string)
              }
              autoHighlight
              renderOption={(task) => `${task.number} ${task.description}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Verknúmer"
                  InputLabelProps={{shrink: true}}
                  error={!!errors.task}
                  helperText={taskText || errors.task}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Autocomplete
              filterOptions={filterOptions}
              options={materials}
              loading={materialsLoading}
              onChange={(_, newValue) =>
                setTime({...mlog, material: newValue && newValue.number})
              }
              value={mlog.material}
              loadingText="Sæki efni…"
              getOptionLabel={(material) =>
                typeof material === "string" ? material : material.number
              }
              getOptionSelected={(option, value) =>
                option.number === ((value as unknown) as string)
              }
              autoHighlight
              renderOption={(material) => `${material.number} ${material.description}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Efni"
                  InputLabelProps={{shrink: true}}
                  error={!!errors.material}
                  helperText={materialText || errors.material}
                />
              )}
            />
          </Grid>

          <Grid item md={3} sm={3} xs={4}>
            <TextField type="number" label="Magn" {...fieldProps("amount")} />
          </Grid>

          <Grid item md={5} sm={4} xs={8}>
            <TextField
              fullWidth
              minRows="3"
              label="Skýring (t.d. staðsetning ofl.)"
              {...fieldProps("comment")}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  style={{width: 90}}
                  disabled={buttonsDisabled}
                  startIcon={<DeleteIcon/>}
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={del}
                >
                  Eyða
                </Button>
              </Grid>

              <Grid item>
                <Button
                  style={{width: 90}}
                  disabled={buttonsDisabled}
                  endIcon={submitIcon}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={_submit}
                >
                  {submitText}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {errors.error ? (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {errors.error}
              </Alert>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
}
