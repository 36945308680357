import firebase from "firebase/app";
import "firebase/firestore";
import { useContext, useEffect } from "react";
import { AppContext } from "./App";
import { useCollection as _useCollection } from "react-firebase-hooks/firestore";
import { logError } from "./errors";

export function useCollection(
  query: firebase.firestore.Query<firebase.firestore.DocumentData>,
  globalLoading = true
): [firebase.firestore.QuerySnapshot | undefined, boolean, Error | undefined] {
  const { setLoading } = useContext(AppContext);

  const [result, loading, error] = _useCollection(query);

  if (error) {
    logError(error);
  }

  if (globalLoading) {
    useEffect(() => {
      if (loading) setLoading(true);
      else setLoading(false);
    }, [loading]);
  }

  return [result, loading, error];
}
