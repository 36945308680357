import { Button } from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { AppUser, Time } from "common/types";
import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { REQUIRED } from "./constants";
import { sendTime } from "./functions";
import { newTime } from "./LogTimes";
import TimeCard from "./TimeCard";
import { Doc } from "./TimeQuery";
import { Subtitle } from "./UI";

export default function Admin({ user }: { user: AppUser }) {
  const app = firebase.app();
  const db = app.firestore();
  const pn = app.auth().currentUser?.phoneNumber || "";
  const [times, loading, error] = useCollection(
    db
      .collection("records")
      .doc("times")
      .collection("admin")
      .where("user", "==", pn)
  );

  async function addTime(kind: "work" | "device") {
    await newTime(db, app, "admin", kind, user.group);
  }

  const _sendTime = async function (
    t: Time
  ): Promise<{ [key: string]: string }> {
    if (!t.employee) return { employee: REQUIRED };
    return await sendTime(t);
  };

  return loading ? null : error ? (
    <Subtitle>
      Aðgerð tókst ekki, athugið nettenginu eða reynið aftur síðar.
    </Subtitle>
  ) : (
    <Fragment>
      {times?.docs.map((t: Doc) => (
        <TimeCard
          key={t.id}
          doc={t}
          admin
          errors={{}}
          submit={_sendTime}
          submitText="Senda"
          submitIcon={<DoneAllIcon />}
        />
      ))}
      <div>
      <Button variant="contained" size="small" onClick={() => addTime("work")}>
        Nýr vinnutími
      </Button>
      <Button variant="contained" style={{ float: "right" }} size="small" onClick={() => addTime("device")}>
        Nýr tækjatími
      </Button>
      </div>
    </Fragment>
  );
}
