import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { isProduction } from "./env";

export function logError(error: any) {
  if (!isProduction()) console.error(error);
  try {
    let app = firebase.app();
    let user = app.auth().currentUser;
    let db = app.firestore();
    let doc = db.collection("errors").doc();
    let _error = JSON.parse(JSON.stringify(error));
    _error.text = (error.toString && error.toString()) || "no error text";
    doc.set({
      user: user?.phoneNumber || null,
      error: Object.assign({}, _error),
      date: new Date().toISOString(),
    });
  } catch (e) {}
}
