import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  makeStyles
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@material-ui/icons/Person";
import { Alert, Autocomplete, createFilterOptions } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { Task } from "common/types";
import firebase from "firebase/app";
import "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { AppContext } from "./App";
import { GENERIC_ERROR } from "./constants";
import { logError } from "./errors";
import useTasks from "./useTasks";

type Doc = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

interface TimeCardProps {
  showName?: boolean;
  admin?: boolean;
  doc: Doc;
  submit: (t: any) => Promise<{ [key: string]: string }>;
  submitText: string;
  submitIcon: JSX.Element;
  errors: { [key: string]: string };
}

type Errors = { [key: string]: string };

type TimeFields =
  | "date"
  | "task"
  | "manager"
  | "hours"
  | "registration"
  | "device"
  | "overtime"
  | "comment"
  | "employee";

const useStyles = makeStyles(() => ({
  name: {
    margin: 0,
    fontWeight: "bold",
  },
}));

export default function TimeCard({
  showName,
  admin,
  doc,
  submit,
  submitText,
  submitIcon,
}: TimeCardProps) {
  let [time, setTime] = useState({ ...doc.data() });
  let [errors, setErrors] = useState<Errors>({});
  let [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  let [debouncedTime] = useDebounce(time, 400);
  let { withLoading } = useContext(AppContext);
  let { loading: tasksLoading, workTasks, deviceTasks, allTasks } = useTasks();
  let classes = useStyles();

  useEffect(() => {
    withLoading(() => doc.ref.update(debouncedTime));
  }, [debouncedTime]);

  const del = async () => {
    setButtonsDisabled(true);
    await doc.ref.delete();
    setButtonsDisabled(false);
  };

  const _submit = async () => {
    setErrors({});
    setButtonsDisabled(true);
    try {
      let _time = { ...time };
      if (time.hours) _time.hours = Number(time.hours);
      if (time.overtime) _time.overtime = Number(time.overtime);
      let errors = await withLoading(async () => await submit(_time));
      if (Object.keys(errors).length === 0) {
        doc.ref.delete();
      } else {
        setErrors(errors);
        setButtonsDisabled(false);
      }
    } catch (e) {
      logError(e);
      setErrors({ error: GENERIC_ERROR });
    }
  };

  function fieldProps(key: TimeFields, helperText?: string) {
    return {
      InputLabelProps: { shrink: true },
      value: time[key] === undefined ? "" : time[key],
      error: !!errors[key],
      helperText: helperText || errors[key],
      onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => setTime({ ...time, [key]: e.target.value }),
    };
  }

  let taskText =
    workTasks.find((task) => task.number === time.task)?.description ||
    (!time.task ? "" : "Sækji verknúmer…");
  let deviceText =
    deviceTasks.find((task) => task.number === time.device)?.description ||
    (!time.device ? "" : "Sækji tæki…");

  let taskFilterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (task: Task) => `${task.number} ${task.description}`,
  });

  return (
    <Card style={{ marginBottom: 20 }} elevation={4}>
      <CardContent>
        {" "}
        <Grid container spacing={2}>
          {showName ? (
            <Grid item xs={12}>
              <p className={classes.name}>{time.name}</p>
            </Grid>
          ) : null}
          <Grid item xs={6} sm={2} md={1}>
            <DatePicker
              required
              error={!!errors.date}
              helperText={errors.date}
              label="Dagsetning"
              value={time["date"] && time["date"] * 1000}
              onChange={(d) =>
                d && setTime({ ...time, date: Math.floor(d.getTime() / 1000) })
              }
              format="d MMM"
            />
          </Grid>

          {admin ? (
            <Grid item xs={6} sm={2} md={1}>
              <TextField
                required
                label="Starfsmaður"
                {...fieldProps("employee")}
              />
            </Grid>
          ) : null}

          <Grid item xs={6} sm={2} md={1}>
            <TextField label="MainManager" {...fieldProps("manager")} />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Autocomplete
              filterOptions={taskFilterOptions}
              options={time.kind == "device" ? workTasks : allTasks}
              loading={tasksLoading}
              onChange={(_, newValue) =>
                setTime({ ...time, task: newValue && newValue.number })
              }
              value={time.task}
              loadingText="Sæki verknúmer…"
              getOptionLabel={(task) =>
                typeof task === "string" ? task : task.number
              }
              getOptionSelected={(option, value) =>
                option.number === ((value as unknown) as string)
              }
              autoHighlight
              renderOption={(task) => `${task.number} ${task.description}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={admin}
                  label="Verknúmer"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.task}
                  helperText={taskText || errors.task}
                />
              )}
            />
          </Grid>

          {time.kind == "device" ? (
            <Grid item xs={12} sm={3}>
              <Autocomplete
                filterOptions={taskFilterOptions}
                options={deviceTasks}
                loading={tasksLoading}
                onChange={(_, newValue) =>
                  setTime({ ...time, device: newValue && newValue.number })
                }
                value={time.device}
                loadingText="Sæki tæki…"
                getOptionLabel={(device) =>
                  typeof device === "string" ? device : device.number
                }
                getOptionSelected={(option, value) =>
                  option.number === ((value as unknown) as string)
                }
                autoHighlight
                renderOption={(device) =>
                  `${device.number} ${device.description}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    label="Tæki"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.device}
                    helperText={deviceText || errors.device}
                  />
                )}
              />
            </Grid>
          ) : null}

          <Grid item xs={time.kind === "device" ? 3 : 6} sm={1}>
            <TextField type="number" label="Tímar" {...fieldProps("hours")} />
          </Grid>

          {time.kind == "work" ? (
            <Grid item xs={6} sm={1}>
              <TextField
                type="number"
                label="Yfirvinnut."
                {...fieldProps("overtime")}
              />
            </Grid>
          ) : null}

          <Grid
            item
            xs={time.kind == "work" ? 12 : 9}
            md={time.kind == "work" ? 5 : 3}
          >
            <TextField
              fullWidth
              minRows="3"
              label="Skýring (t.d. staðsetning ofl.)"
              {...fieldProps("comment")}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  style={{ width: 90 }}
                  disabled={buttonsDisabled}
                  startIcon={<DeleteIcon />}
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={del}
                >
                  Eyða
                </Button>
              </Grid>

              <Grid item>
                <Chip
                  style={{ marginTop: 4 }}
                  size="small"
                  avatar={
                    time.kind === "work" ? (
                      <PersonIcon />
                    ) : (
                      <AirportShuttleIcon />
                    )
                  }
                  label={time.kind === "work" ? "Vinnutími" : "Tækjatími"}
                />
              </Grid>

              <Grid item>
                <Button
                  style={{ width: 90 }}
                  disabled={buttonsDisabled}
                  endIcon={submitIcon}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={_submit}
                >
                  {submitText}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {errors.error ? (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {errors.error}
              </Alert>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
}
