import { Button } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { AppUser, Time } from "common/types";
import { GENERIC_ERROR, REQUIRED } from "./constants";
import { logError } from "./errors";
import TimeCard from "./TimeCard";
import { Subtitle } from "./UI";
import { useCollection } from "./useCollection";

export async function newTime(
  db: firebase.firestore.Firestore,
  app: firebase.app.App,
  collection: string,
  kind: "work" | "device",
  group: Number
) {
  let time = db.collection("records").doc("times").collection(collection).doc();
  await time.set({
    kind,
    date: Math.floor(Date.now() / 1000),
    user: app.auth().currentUser?.phoneNumber,
    group: group,
    state: "pending",
  });
}

function validateTime(time: number) {
  let quarter: number = time - Math.floor(time);
  if (!(quarter == 0 || quarter == 0.25 || quarter == 0.5 || quarter == 0.75)) {
    return `Aukastafir í tíma mega bara vera ,25 ,5 eða ,75`;
  }
  return null;
}

export function validateAndMove(user: AppUser, collection: 'pending' | 'confirmed', doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
  return async function (t: Time): Promise<{ [key: string]: string }> {
      if (!t.date) return { date: REQUIRED };

      if (!t.task && !t.comment)
        return {
          task: `${REQUIRED}, eða skýring`,
          comment: `${REQUIRED}, eða verknúmer`,
        };

      if (t.kind === "device" && !t.device) return { device: REQUIRED };

      if (!t.hours && t.kind === "device") return { hours: REQUIRED };

      if (!t.hours && t.kind === "work" && !t.overtime)
        return {
          hours: `${REQUIRED}, eða yfirvinnutímar`,
          overtime: `${REQUIRED}, eða tímar`,
        };

      if (t.hours) {
        let error = validateTime(t.hours);
        if (error) return { hours: error };
      }

      if (t.kind === "work" && t.overtime) {
        let error = validateTime(t.overtime);
        if (error) return { overtime: error };
      }

      let nextDoc = firebase
        .app()
        .firestore()
        .collection("records")
        .doc("times")
        .collection(collection)
        .doc(doc.id);

      try {
        const name = t.name == null || t.name == "" ? user.name : t.name;
        await nextDoc.set({ ...t, state: collection, name });
      } catch (e) {
        logError(e);
        return { error: GENERIC_ERROR };
      }
    
    return {};
  };
}

function LogTimes({ user }: { user: AppUser }) {
  const app = firebase.app();
  const db = app.firestore();
  const pn = app.auth().currentUser?.phoneNumber || "";

  const [times, loading, error] = useCollection(
    db
      .collection("records")
      .doc("times")
      .collection("drafts")
      .where("user", "==", pn)
  );

  async function addTime(kind: "work" | "device") {
    await newTime(db, app, "drafts", kind, user.group);
  }

  return loading ? null : error ? (
    <Subtitle>
      Aðgerð tókst ekki, athugið nettenginu eða reynið aftur síðar.
    </Subtitle>
  ) : (
    <Fragment>
      <TransitionGroup>
        {times?.docs.map((d) => {
          return (
            <CSSTransition key={d.id} timeout={500} classNames="item">
              <div>
                <TimeCard
                  key={d.id}
                  doc={d}
                  submit={validateAndMove(user, 'pending', d)}
                  submitText="Senda"
                  submitIcon={<SendIcon />}
                  errors={{}}
                />
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      <div>
        <Button
          variant="contained"
          size="small"
          onClick={() => addTime("work")}
        >
          Nýr vinnutími
        </Button>
        <Button
          style={{ float: "right" }}
          variant="contained"
          size="small"
          onClick={() => addTime("device")}
        >
          Nýr tækjatími
        </Button>
      </div>
    </Fragment>
  );
}

export default LogTimes;
