import { useEffect, useState } from "react";
import { Task } from "common/types";
import { getTasks } from "./functions";

function useTasks(): {
  loading: boolean;
  allTasks: Task[];
  workTasks: Task[];
  deviceTasks: Task[];
} {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getTasks().then((res) => {
      setLoading(false);
      if (res.data.kind == "result") {
        const resTasks: Task[] = res.data.tasks;
        resTasks.sort((a, b) => (a.number > b.number ? -1 : 1));
        setTasks(resTasks);
      }
    });
  }, []);

  return {
    loading,
    allTasks: tasks,
    workTasks: tasks.filter(
      (t) => !t.description.toLowerCase().endsWith("_tímar")
    ),
    deviceTasks: tasks.filter((t) =>
      t.description.toLowerCase().endsWith("_tímar")
    ),
  };
}

export default useTasks;
