import firebase from "firebase/app";
import "firebase/functions";

import {
  SendTimeResults,
  GetUserResults,
  Time,
  GetTasksResults,
} from "common/types";
import { GENERIC_ERROR } from "./constants";
import { logError } from "./errors";
import { isProduction, useProductionApi } from "./env";

function getFunction(name: string) {
  if (isProduction() || useProductionApi()) {
    return firebase.app().functions("europe-west1").httpsCallable(name);
  } else {
    // Should we export a function instance in index.tsx and reuse that?
    const fs = firebase.app().functions("europe-west1");
    fs.useEmulator("localhost", 5001);
    return fs.httpsCallable(name);
  }
}

export function getUser(): Promise<{ data: GetUserResults }> {
  return getFunction("getUser")();
}

export async function sendTime(data: Time): Promise<{ [key: string]: string }> {
  const result = await getFunction("sendTime")(data);
  const resData: SendTimeResults = result.data;
  switch (resData.kind) {
    case "error":
      logError(resData);
      let error = resData.error;
      if (!error) return { error: GENERIC_ERROR };
      else if (typeof error === "string") return { error };
      else return error as { [key: string]: string };
    case "result":
      return {};
  }
}

var TASKS_CACHE: { data: GetTasksResults } | null = null;
var TASKS_REQUESTED = false;
var TASKS_AWAITERS: {
  resolve: (res: { data: GetTasksResults }) => void;
  reject: (reason?: any) => void;
}[] = [];

export async function getTasks(): Promise<{ data: GetTasksResults }> {
  if (TASKS_CACHE) return Promise.resolve(TASKS_CACHE);
  if (TASKS_REQUESTED)
    return new Promise((resolve, reject) => {
      TASKS_AWAITERS.push({ resolve, reject });
    });
  TASKS_REQUESTED = true;
  let req = getFunction("getTasks")();
  try {
        const res = await req;
        TASKS_CACHE = res;
        for (let awaiter of TASKS_AWAITERS) {
            awaiter.resolve(res);
        }
        return res;
    } catch (reason_1) {
        for (let awaiter_1 of TASKS_AWAITERS) {
            awaiter_1.reject(reason_1);
        }
        return { data: { kind: "error" } };
    }
}
