import { AppUser } from "common/types";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { Doc } from "./TimeQuery";

export function useTimes(user: AppUser, state: 'pending' | 'confirmed' | 'sent'): (start?: Date, end?: Date) => [Doc[], boolean, Error | undefined] {
  return (start?: Date, end?: Date) => {
    const db = firebase.app().firestore();

    let query = db.collection("records")
      .doc("times")
      .collection(state)
      .orderBy("date", "asc")

    if (start && end) {
      query = query
	.where("date", ">=", start.getTime() / 1000)
	.where("date", "<=", end?.getTime() / 1000);
    }

    switch (user.role) {
     case "manager":
       query = query.where("group", "==", user.group);
       break;
     case "user":
       query = query.where("employee", "==", user.number);
       break;
    }

    const [times, loading, error] = useCollection(query);

    return [times?.docs ?? [], loading, error];
  }
}
