import DoneAllIcon from "@material-ui/icons/DoneAll";
import "firebase/firestore";
import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { AppUser } from "common/types";
import { validateAndMove } from "./LogTimes";
import TimeCard from "./TimeCard";
import { Doc, TimeQuery } from "./TimeQuery";
import { Subtitle } from "./UI";
import { useTimes } from "./useTimes";

const confirmTimes = ({ times, user }: { times: Doc[], user: AppUser }) => (
  <Fragment>
    {(times?.length || 0) < 1 ? (
      <Subtitle>Engir tímar til að staðfesta</Subtitle>
    ) : null}
    <TransitionGroup>
      {times?.map((d) => {
        return (
          <CSSTransition key={d.id} timeout={500} classNames="item">
            <div>
              <TimeCard
                key={d.id}
                doc={d}
                showName
                submit={validateAndMove(user, 'confirmed', d)}
                submitText="Skrá"
                submitIcon={<DoneAllIcon />}
                errors={{}}
              />
            </div>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  </Fragment>
);

export default function ConfirmTimes({ user }: { user: AppUser }) {
  return (
    <TimeQuery
      showDayCount={true}
      allowShowAll={true}
      user={user}
      query={useTimes(user, 'pending')}
      RenderTimes={confirmTimes}
    />
  );
}
