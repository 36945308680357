import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { endOfDay, startOfDay } from "date-fns";
import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useState } from "react";
import { AppUser } from "common/types";
import { Subtitle } from "./UI";
import WeekPicker, { endOfWeek, startOfWeek } from "./WeekPicker";

export type Doc = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

const ALL_EMPLOYESS = "Allir starfsmenn";

const filterTimes = (docs: Doc[], from?: Date, to?: Date) => {
  if (!from || !to) return docs;
  return docs.filter((d) => {
    let date: number = d.get("date") * 1000;
    return date >= from.valueOf() && date <= to.valueOf();
  });
};

type TimeQueryProps = {
  user: AppUser;
  query: (start?: Date, end?: Date) => [Doc[], boolean, Error | undefined];
  RenderTimes: (props: { times: Doc[], user: AppUser }) => JSX.Element | null;
  allowShowAll: boolean;
  showDayCount: boolean;
  recordName?: string;
};

export function TimeQuery({
  user,
  query,
  RenderTimes,
  allowShowAll,
  showDayCount,
  recordName,
}: TimeQueryProps) {
  const today = new Date();

  const [employee, setEmployee] = useState(ALL_EMPLOYESS);
  const [week, setWeek] = useState<{ start?: Date; end?: Date }>({
    start: startOfWeek(today),
    end: endOfWeek(today),
  });

  const [times, loading, error] = query(week.start, week.end);

  const numberForDay = (date: Date) => {
    if (showDayCount)
      return filterTimes(times, startOfDay(date), endOfDay(date))
        .length;
    else return null;
  };

  const timesByDate = filterTimes(times, week.start, week.end);

  const employees = (() => {
    const s = new Set(timesByDate?.map((d) => d.get("name") as string));
    return Array.from(s).sort();
  })();

  const filteredTimes = timesByDate
    ?.filter((d) => {
      if (employee === ALL_EMPLOYESS) return true;
      return d.get("name") === employee;
    })
    .sort((a, b) => (a.get("name") >= b.get("name") ? 1 : -1));

  return error ? (
    <Subtitle>
      Aðgerð tókst ekki, athugið nettenginu eða reynið aftur síðar
    </Subtitle>
  ) : (
    <Fragment>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={5}>
          <WeekPicker
            clearable={allowShowAll}
            numberForDay={numberForDay}
            onChange={(start, end) => setWeek({ start, end })}
          />
        </Grid>
        <Grid item xs={7}>
          <Autocomplete
            noOptionsText={`Engir með ${recordName ?? "tíma"}`}
            options={[ALL_EMPLOYESS, ...employees]}
            loading={loading}
            onChange={(_, newValue) => setEmployee(newValue || ALL_EMPLOYESS)}
            value={employee}
            loadingText={`Sækji ${recordName ?? "tíma"}…`}
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                label="Starfsmaður"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
      </Grid>
      {loading ? <Subtitle>Sæki tíma…</Subtitle> : null}
      {filteredTimes ? <RenderTimes times={filteredTimes} user={user} /> : null}
    </Fragment>
  );
}
