import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { isProduction, useProductionApi } from "./env";


const app = firebase.initializeApp({
  apiKey: "AIzaSyCByyZZP2naRcacct8NGAPo7iRW-G9KEhA",
  authDomain: "timak-app.firebaseapp.com",
  databaseURL: "https://timak-app.firebaseio.com",
  projectId: "timak-app",
  storageBucket: "timak-app.appspot.com",
  messagingSenderId: "148876335618",
  appId: "1:148876335618:web:8b3b2d0cef409954cae51b",
  measurementId: "G-ETJX74W2FC",
});

firebase.auth().languageCode = "is";

if (!isProduction() && !useProductionApi()) {
  firebase.auth().settings.appVerificationDisabledForTesting = true;
  app.auth().useEmulator("http://localhost:9099");
  app.functions().useEmulator("localhost", 5001);
  app.firestore().settings({ host: "localhost:8080", ssl: false });
}

ReactDOM.render(<App />, document.getElementById('root'));
