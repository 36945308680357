import {AppUser} from "common/types";
import firebase from "firebase";
import {useCollection} from "react-firebase-hooks/firestore";
import {Doc} from "./TimeQuery";

export function useMaterialLogs(user: AppUser, state: 'pending' | 'logged'): (start?: Date, end?: Date) => [Doc[], boolean, Error | undefined] {
  return (start?: Date, end?: Date) => {
    const db = firebase.app().firestore();

    let query = db.collection("records")
      .doc("materials")
      .collection(state)
      .orderBy("date", "asc")

    if (start && end) {
      query = query
        .where("date", ">=", start.getTime() / 1000)
        .where("date", "<=", end?.getTime() / 1000);
    }

    const [mlogs, loading, error] = useCollection(query);

    return [mlogs?.docs ?? [], loading, error];
  }
}
