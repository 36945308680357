import { Button, Grid, TextField } from "@material-ui/core";
import firebase from "firebase/app";
import "firebase/auth";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { AppContext } from "./App";
import { GENERIC_ERROR } from "./constants";
import { logError } from "./errors";

interface Start {
  kind: "start";
}

interface Error {
  kind: "error";
  msg: string;
}

interface Confirm {
  kind: "confirm";
  result: firebase.auth.ConfirmationResult;
}

type LoginState = Start | Confirm | Error;

function Login() {
  const [input, setInput] = useState("");
  const [state, setState] = useState<LoginState>({ kind: "start" });
  const { loading, setLoading } = useContext(AppContext);
  const submitButton = useRef(null);

  useEffect(() => {
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      submitButton.current,
      {
        size: "invisible",
      }
    );
  }, []);

  async function login() {
    switch (state.kind) {
      case "start": {
        try {
          setLoading(true);
          let phone = input.startsWith("+") ? input : `+354${input}`;
          let res = await firebase
            .auth()
            .signInWithPhoneNumber(phone, (window as any).recaptchaVerifier);
          setLoading(false);
          setInput("");
          setState({ kind: "confirm", result: res });
        } catch (err) {
          logError({ login_phone_number: input, ...(typeof err === 'object' ? err : {}) });
          setLoading(false);
          setState({ kind: "error", msg: GENERIC_ERROR });
        }
        break;
      }
      case "confirm": {
        try {
          setLoading(true);
          await state.result.confirm(input);
          setLoading(false);
        } catch (err) {
          logError(err);
          setLoading(false);
          setState({ kind: "error", msg: (err as any).code });
        }
      }
    }
  }

  function prompt() {
    switch (state.kind) {
      case "start":
        return "Símanúmer (7 tölur)";
      case "confirm":
        return "Staðfestingarkóði (6 tölur)";
      case "error":
        return state.msg;
    }
  }

  function pattern() {
    switch (state.kind) {
      case "start":
        return "\\d{7}";
      case "confirm":
        return "\\d{6}";
      case "error":
        return "";
    }
  }

  function button() {
    switch (state.kind) {
      case "start":
        return "Áfram";
      case "confirm":
        return "Staðfesta";
      case "error":
        return "Staðfesta";
    }
  }

  function restart() {
    setState({ kind: "start" });
    setInput("");
  }

  return (
    <Fragment>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          login();
          e.preventDefault();
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              inputProps={{ pattern: pattern() }}
              fullWidth
              type="tel"
              margin="normal"
              label={`${prompt()}`}
              value={input}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInput(e.target?.value)
              }
            />
          </Grid>
          {state.kind != "confirm" ? null : (
            <Grid item xs={6}>
              <Button color="secondary" onClick={restart}>
                Byrja aftur
              </Button>
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              onClick={login}
              disabled={loading > 0}
              ref={submitButton}
              color="primary"
            >
              {button()}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

export default Login;
