import firebase from "firebase/app";
import "firebase/auth";
import { useState, useEffect, useContext } from "react";
import { getUser } from "./functions";
import { AppUser } from "common/types";
import { AppContext } from "./App";

export type UserState = AppUser | "loading" | "error" | "none";

function useUser() {
  const { setLoading } = useContext(AppContext);
  const [user, setUser] = useState<UserState>("loading");
  useEffect(() => {
    setLoading(true);
    return firebase
      .auth()
      .onAuthStateChanged(async (user: firebase.User | null) => {
        if (user) {
          try {
            let akUser = await getUser();
            setLoading(false);
            if (akUser.data.kind == "error") setUser("error");
            else setUser(akUser.data);
          } catch (err) {
            setLoading(false);
            setUser("error");
          }
        } else {
          setLoading(false);
          setUser("none");
        }
      });
  }, []);
  return user;
}

export default useUser;
