import {
  Accordion,
  AccordionDetails,
  Chip,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import PersonIcon from "@material-ui/icons/Person";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { format, isWeekend, startOfDay } from "date-fns";
import React, { Fragment } from "react";
import { AppUser, Task, Time } from "common/types";
import { Doc, TimeQuery } from "./TimeQuery";
import { Subtitle } from "./UI";
import isLocale from "date-fns/locale/is";
import useTasks from "./useTasks";
import { AddAlarm } from "@material-ui/icons";
import { useTimes } from "./useTimes";

const AccordionSummary = withStyles({})(MuiAccordionSummary);

const WeekendAccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[100],
  },
}))(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 540,
  },
  tableContainer: {
    "&::-webkit-scrollbar": {
      height: 3,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const WorkIcon = () => (
  <PersonIcon
    style={{ fontSize: "1rem", marginRight: 8, verticalAlign: "text-top" }}
  />
);

const DeviceIcon = () => (
  <AirportShuttleIcon
    style={{ fontSize: "1rem", marginRight: 8, verticalAlign: "text-top" }}
  />
);

const OverIcon = () => (
  <AddAlarm
    style={{ fontSize: "1rem", marginRight: 8, verticalAlign: "text-top" }}
  />
);

const CountChips = ({ times }: { times: Time[] }) => {
  let device = times.reduce(
    (sum, time) => sum + (time.kind === "device" ? time.hours || 0 : 0),
    0
  );
  let hours = times.reduce(
    (sum, time) => sum + (time.kind === "work" ? time.hours || 0 : 0),
    0
  );
  let overtime = times.reduce(
    (sum, time) => sum + (time.kind === "work" ? time.overtime || 0 : 0),
    0
  );
  return (
    <Fragment>
      {device > 0 ? (
        <Chip
          color="default"
          size="small"
          style={{ marginRight: hours > 0 ? 10 : 0 }}
          label={
            <Tooltip title="Tækjatímar">
              <div>
                <DeviceIcon />
                {device}
              </div>
            </Tooltip>
          }
        />
      ) : null}
      {overtime > 0 ? (
        <Chip
          color="default"
          size="small"
          style={{ marginRight: hours > 0 ? 10 : 0 }}
          label={
            <Tooltip title="Yfirvinna">
              <div>
                <OverIcon />
                {overtime}
              </div>
            </Tooltip>
          }
        />
      ) : null}
      {hours > 0 ? (
        <Chip
          color={hours < 8 ? "default" : hours > 8 ? "secondary" : "primary"}
          size="small"
          label={
            <Tooltip title="Vinnutímar">
              <div>
                <WorkIcon />
                {hours}
              </div>
            </Tooltip>
          }
        />
      ) : null}
    </Fragment>
  );
};

const dayAccordionSummary = (day: number) =>
  isWeekend(day) ? WeekendAccordionSummary : AccordionSummary;

function EmployeeGroup({
  name,
  days,
  tasks,
}: {
  name: string;
  days: Map<number, Time[]>;
  tasks: Map<string, Task>;
}) {
  const classes = useStyles();
  return (
    <Fragment>
      <Subtitle>{name}</Subtitle>
      {Array.from(days.entries()).map(([day, times]) => {
        let Accs = dayAccordionSummary(day);
        return (
          <Accordion key={day} color="">
            <Accs expandIcon={<ExpandMoreIcon />}>
              <div style={{ flexGrow: 1, alignSelf: "center" }}>
                {format(day, "eee do LLL", { locale: isLocale })}
              </div>
              <div style={{ flexGrow: 0 }}>
                <CountChips times={times} />
              </div>
            </Accs>
            <AccordionDetails>
              <TableContainer className={classes.tableContainer}>
                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Verk</TableCell>
                      <TableCell style={{ width: 100 }} align="right">
                        Tímar / yfirv.
                      </TableCell>
                      <TableCell align="right">Athugasemd</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {times.map((t, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {t.kind === "work" ? (
                            <Tooltip title="Vinnutími">
                              <span>
                                <WorkIcon />
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Tækjatími">
                              <span>
                                <DeviceIcon />
                              </span>
                            </Tooltip>
                          )}{" "}
                          <TaskDescription time={t} tasks={tasks} />
                        </TableCell>
                        <TableCell align="right">
                          {t.hours || 0} /{" "}
                          {t.kind == "work" ? t.overtime || 0 : 0}
                        </TableCell>
                        <TableCell align="right">{t.comment}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Fragment>
  );
}

function TaskDescription({ time, tasks }: { time: Time, tasks: Map<string, Task> }) {
  switch (time.kind) {
    case "work":
      return <>{`${time.task} - ${tasks.get(time.task)?.description}`}</>;
    case "device":
      return <>
        {`${time.task} - ${tasks.get(time.task)?.description}`}
        <br />
        <span style={{ fontSize: 11, color: '#ccc' }}>{`${time.device} - ${tasks.get(time.device)?.description}`}</span>
      </>;
    default:
      return <></>;
  }
}

function sentTimes({ times }: { times: Doc[] }) {
  if (!times || times.length === 0) return null;

  let { loading: tasksLoading, allTasks: tasks } = useTasks();

  if (tasksLoading) return null;

  const mappedTasks = new Map(tasks.map((t) => [t.number, t]));

  let employees = times.reduce((prev, time) => {
    let emp = time.get("name");
    let day = startOfDay(time.get("date") * 1000).getTime();

    if (prev[emp] && prev[emp].has(day)) {
      prev[emp].get(day)!.push(time.data() as Time);
    } else if (prev[emp]) {
      prev[emp].set(day, [time.data() as Time]);
    } else {
      prev[emp] = new Map();
      prev[emp].set(day, [time.data() as Time]);
    }

    return prev;
  }, {} as { [key: string]: Map<number, Time[]> });

  return (
    <Fragment>
      {Object.entries(employees).map(([name, days]) => (
        <div key={name} style={{ marginBottom: 20 }}>
          <EmployeeGroup name={name} days={days} tasks={mappedTasks} />
        </div>
      ))}
    </Fragment>
  );
}

type TimeQueryT = (user: AppUser) => (start?: Date | undefined, end?: Date | undefined) => [Doc[], boolean, Error | undefined]

const timeQuery: TimeQueryT = (user: AppUser) => (start?: Date, end?: Date) => {
  const [confTimes, confLoading, confError] = useTimes(user, 'confirmed')(start, end);
  const [sentTimes, sentLoading, sentError] = useTimes(user, 'sent')(start, end);
  if (confLoading || sentLoading) return [[], true, undefined];
  if (confError || sentError) return [[], false, confError || sentError];
  return [confTimes.concat(sentTimes), false, undefined];
}

export default function SentTimes({ user }: { user: AppUser }) {
  return (
    <TimeQuery
      showDayCount={false}
      allowShowAll={false}
      query={user.role === 'admin' ? useTimes(user, 'sent') : timeQuery(user)}
      user={user}
      RenderTimes={sentTimes}
    />
  );
}
