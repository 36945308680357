import { Typography } from "@material-ui/core";
import React from "react";

export const Heading: React.FunctionComponent = ({ children }) => (
  <Typography variant="h6" color="textPrimary">
    {children}
  </Typography>
);

export const Subtitle: React.FunctionComponent = ({ children }) => (
  <Typography variant="subtitle1" color="textPrimary">
    {children}
  </Typography>
);

export const Label: React.FunctionComponent<{ className: string }> = ({
  children,
  className,
}) => <Typography className={className} color="textPrimary">{children}</Typography>;

export const Caption: React.FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => <Typography variant="caption" display="block" className={className} color="textSecondary">{children}</Typography>;