import { Box, Button, Card, CardContent, Fab, Grid, InputLabel, makeStyles, Modal, NativeSelect, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TrashIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from "@material-ui/icons/Save";
import { Role } from 'common/types';
import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { AppContext } from "./App";
import { Caption, Heading, Subtitle } from "./UI";

const styles = makeStyles(() => ({
  fab: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none"
    }
  },
}));

export default function AdminUsers() {
  const db = firebase.app().firestore();
  const classes = styles()
  const { withLoading } = useContext(AppContext);
  const [users, loading, error] = useCollection(db.collection("users"));
  const [open, setOpen] = useState(false)
  const [newUser, setNewUser] = useState<Partial<User>>({ group: 0, role: 'user' })
  const [errors, setErrors] = useState<Errors>({});

  let sortedUsers = users?.docs.sort((a, b) => a.data().name?.localeCompare(b.data().name))

  async function submitNewUser() {
    if (isNaN(Number(newUser.id)) || newUser.id?.length !== 7) {
      setErrors({ id: 'ekki 7 stafa tala' })
      return
    }

    if (isNaN(Number(newUser.number)) || newUser.number?.toString().length !== 5) {
      setErrors({ number: 'ekki 5 stafa tala' })
      return
    }

    let newDoc = db.doc(`users/+354${newUser.id}`)
    await newDoc.set({
      number: newUser.number,
      group: newUser.group,
      role: newUser.role
    })
  }

  return loading ? null : error ?
    <Subtitle>
      Aðgerð tókst ekki, athugið nettenginu eða reynið aftur síðar.
    </Subtitle>
    : <Fragment>
      <Fab onClick={() => setOpen(true)} className={classes.fab} variant="round" color="primary" aria-label="add">
        <AddIcon />
      </Fab>

      {sortedUsers?.map(doc => <UserCard key={doc.id} user={{ ...doc.data(), id: doc.id.slice(-7) } as User} />)}

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          // @ts-ignore
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <Heading>Nýr notandi</Heading>
            </Grid>

            <Grid item xs={6}>
              <TextField label="Símanúmer"
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: { input: classes.input } }}
                value={newUser.id}
                error={!!errors['id']}
                type="number"
                helperText={errors['id']}
                onChange={(e) => {
                  setNewUser({ ...newUser, id: e.target.value })
                  setErrors({})
                }} />
            </Grid>

            <Grid item xs={6}>
              <TextField label="Númer"
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: { input: classes.input } }}
                value={newUser.number}
                error={!!errors['number']}
                type="number"
                helperText={errors['number']}
                onChange={(e) => {
                  setNewUser({ ...newUser, number: Number(e.target.value) })
                  setErrors({})
                }} />
            </Grid>

            <Grid item xs={6}>
              <InputLabel shrink htmlFor="group">Hópur</InputLabel>
              <NativeSelect id="group" value={newUser.group}
                fullWidth
                onChange={e => setNewUser({ ...newUser, group: Number(e.target.value) })}>
                <option key={0} value={0}>0</option>
                <option key={1} value={1}>1</option>
                <option key={2} value={2}>2</option>
                <option key={3} value={3}>3</option>
                <option key={4} value={4}>4</option>
                <option key={5} value={5}>5</option>
                <option key={newUser.number} value={newUser.number}>{newUser.number}</option>
              </NativeSelect>
            </Grid>

            <Grid item xs={6}>
              <InputLabel shrink htmlFor="role">Hlutverk</InputLabel>
              <NativeSelect id="role" value={newUser.role}
                fullWidth
                onChange={e => setNewUser({ ...newUser, role: e.target.value as Role })}>
                <option key={'user'} value={'user'}>User</option>
                <option key={'manager'} value={'manager'}>Manager</option>
                <option key={'admin'} value={'admin'}>Admin</option>
              </NativeSelect>
            </Grid>

            <Grid item xs={8}>
              <Caption>Þessi aðgerð yfirskrifar ef til er notandi með sama símanúmeri</Caption>
            </Grid>

            <Grid item xs={2} justifyContent="flex-end">
              <Button
                style={{ marginTop: 18 }}
                endIcon={<SaveIcon />}
                color="primary"
                variant="contained"
                size="small"
                onClick={() => withLoading(submitNewUser)}>
                Vista
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
}

interface User {
  id: string,
  name?: string,
  number: number,
  group: number,
  role: Role
}

type Errors = { [key: string]: string };

function UserCard({ user }: { user: User }) {
  const db = firebase.app().firestore();
  const classes = styles()
  const { withLoading } = useContext(AppContext);
  const [editedUser, setUser] = useState({ ...user })
  const [errors, setErrors] = useState<Errors>({});

  function isChanged() {
    return user.id !== editedUser.id ||
      user.group !== editedUser.group ||
      user.role !== editedUser.role
  }

  async function submit() {
    if (isNaN(Number(editedUser.id)) || editedUser.id.length !== 7) {
      setErrors({ id: 'ekki 7 stafa tala' })
      return
    }

    if (user.id !== editedUser.id) {
      await db.doc(`users/+354${user.id}`).delete()
    }
    let updatedDoc = db.doc(`users/+354${user.id}`)
    await updatedDoc.set({
      name: editedUser.name,
      number: editedUser.number,
      group: editedUser.group,
      role: editedUser.role
    })
  }

  return <Card style={{ marginBottom: 20 }} elevation={4}>
    <CardContent>
      <Grid container justifyContent="space-between">
        <Grid item sm={5} xs={12}><Subtitle>{user.name ?? "<nafnlaus>"} ({user.number})</Subtitle></Grid>

        <Grid item sm={2}>
          <TextField label="Símanúmer"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ classes: { input: classes.input } }}
            value={editedUser.id.slice(-7)}
            error={!!errors['id']}
            type="number"
            helperText={errors['id']}
            onChange={(e) => {
              setUser({ ...editedUser, id: e.target.value })
              setErrors({})
            }} />
        </Grid>

        <Grid item sm={2}>
          <InputLabel shrink htmlFor="group">Hópur</InputLabel>

          <NativeSelect id="group" value={editedUser.group}
            fullWidth
            onChange={e => setUser({ ...editedUser, group: Number(e.target.value) })}>
            <option key={0} value={0}>0</option>
            <option key={1} value={1}>1</option>
            <option key={2} value={2}>2</option>
            <option key={3} value={3}>3</option>
            <option key={4} value={4}>4</option>
            <option key={5} value={5}>5</option>
            <option key={user.number} value={user.number}>{editedUser.number}</option>
          </NativeSelect>
        </Grid>

        <Grid item sm={2}>
          <InputLabel shrink htmlFor="role">Hlutverk</InputLabel>

          <NativeSelect id="role" value={editedUser.role}
            fullWidth
            onChange={e => setUser({ ...editedUser, role: e.target.value as Role })}>
            <option key={'user'} value={'user'}>User</option>
            <option key={'manager'} value={'manager'}>Manager</option>
            <option key={'admin'} value={'admin'}>Admin</option>
          </NativeSelect>
        </Grid>

        <Grid container justifyContent="space-between">
          <Grid item sm={1}>
            <Button
              style={{ marginTop: 12, width: 75 }}
              endIcon={<TrashIcon />}
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => {
                if (window.confirm(`Viltu eyða ${user.name} (${user.number})`)) {
                  db.doc(`users/+354${user.id}`).delete()
                }
              }}>
              Eyða
            </Button>
          </Grid>

          <Grid item sm={1}>
            <Button
              style={{ marginTop: 12, width: 75 }}
              disabled={!isChanged()}
              endIcon={<SaveIcon />}
              color="primary"
              variant="contained"
              size="small"
              onClick={() => withLoading(submit)}>
              Vista
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
}